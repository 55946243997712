import React, {useState, createContext, useCallback} from "react";
import {Artist} from "./AllBeats/AllBeats";
import firebase from "firebase";

// Create Context Object
export const AppContext = createContext<any>(null);

// Create a provider for components to consume and subscribe to changes
export const AppContextProvider = (props: any) => {

    const [artists, setArtists] = useState<Artist[]>([]);
    const [genres, setGenres] = useState<any>([]);
    const [beats, setBeats] = useState<any>();

     return (
        <AppContext.Provider value={[artists, setArtists, genres, setGenres, beats, setBeats]}>
            {props.children}
        </AppContext.Provider>
    );
};