import React, {useContext, useEffect, useState} from 'react';
import './App.css';
import firebase from "firebase";
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MusicNote from '@material-ui/icons/MusicNote';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import Album from '@material-ui/icons/Album';
import {Artists} from "./Artists/Artists";
import {Genres} from "./Genres/Genres";
import {AllBeats} from "./AllBeats/AllBeats";
import AudioPlayer from 'react-h5-audio-player';
import './AudioPlayer.css';
import Paper from "@material-ui/core/Paper/Paper";
import {FirebaseTools} from "./Tools/FirebaseTools";
import CloudUploadOutlined from '@material-ui/icons/CloudUpload';
import {FileDrop} from "react-file-drop";
import {
    Backdrop,
    Box,
    Button, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress,
    TextField,
    Typography
} from "@material-ui/core";
import WarningTwoToneIcon from '@material-ui/icons/WarningTwoTone';
import {AudioTools} from "./Tools/AudioTools";
import {AppContext} from "./AppContext";
import {AsciiLoader} from "./AsciiLoader";
import Loader from 'react-loader-spinner'
import useWindowDimensions from "./Hooks/UseWindowDImension";

function App() {
    const [artists, setArtists, genres, setGenres, beats, setBeats] = useContext(AppContext);

    useEffect(() => {

        getData().then();

        /*    firebase.database().ref('beats').push({
                bpm: 150, fullPath: "oui/bon", name: "Beat 23", key: "G"
            })*/

    }, []);



    const [currentlyPlaying, setCurrentlyPlaying] = useState<string>("");

    const [loading, setLoading] = useState(true);

    const [fileHovering, setFileHovering] = useState<boolean>(false);

    const [uploadingBeat, setUploadingBeat] = useState<boolean>(false);
    const [uploadingBeatPerc, setUploadingBeatPerc] = useState<number>(0);

    const [cancelState, setCancelState] = useState<boolean>(false);

    const [bpmValue, setBpmValue] = useState<string>('');
    const [nameValue, setNameValue] = useState<string>('');
    const [keyValue, setKeyValue] = useState<string>('');

    const [storageRef, setStorageRef] = useState<firebase.storage.Reference>();
    const [uploadTask, setUploadTask] = useState<firebase.storage.UploadTask>();
    const [uploadComplete, setUploadComplete] = useState<boolean>(false);
    const [canceling, setCanceling] = useState<boolean>(false);

    const getData = async (): Promise<void> => {

     const artistsData = await FirebaseTools.getArtists();
     const genresData = await FirebaseTools.getGenres();
     const beatsData = await FirebaseTools.getBeats();

     setArtists(artistsData);
     setGenres(genresData);
     setBeats(beatsData);

     setTimeout(() => {
         setLoading(false);
         Promise.resolve()
     }, 200);
    };


    const playBeat = (src: string) => {
        setCurrentlyPlaying(src)
    };

    const menuElements = [
        {name: 'All Beats', iconComponent: <MusicNote/>, menuComponent: 1},
        {name: 'Artists', iconComponent: <AssignmentInd/>, menuComponent: 2},
        {name: 'Genres', iconComponent: <Album/>, menuComponent: 3}
    ];

    const [selectedComponent, setSelectedComponent] = useState<number>(1);


    const cancelUpload = () => {
        const done = () => {
            setCanceling(false);
            setCancelState(false);
            setUploadingBeat(false);
        };
        setCanceling(true);
        if(uploadComplete){
            //Upload is not complete yet, let's cancel
            storageRef && storageRef.delete().then(() => {
               done()
            });
        }
        else{
            uploadTask && uploadTask.cancel();
            done()
            //Upload is complete, but user wanted to cancel. Let's delete the file
            // storageRef.delete(); // will delete all your files
        }
    };

    const publishBeat= () => {
        if(storageRef) {
            FirebaseTools.publishBeat(nameValue, keyValue, +bpmValue, storageRef.fullPath).then((data) => {
                if(beats != null && typeof beats[Symbol.iterator] === 'function') {
                    setBeats([...beats, data])
                } else {
                    setBeats([data])
                }
                setUploadingBeat(false);
                setNameValue('');
                setKeyValue('');
                setBpmValue('');
                setUploadingBeatPerc(0)
                //getData()
            })
        }
        console.log(storageRef)
    };

    const uploadBeat = (files: FileList | null) => {
        setUploadingBeat(true);
        if(files && files[0]) {
            setNameValue(files[0].name.replace(/\.[^/.]+$/, "") || '');
            AudioTools.getBpm(files[0]).then((bpm) => {
                setBpmValue(bpm)
            });
            const sub =  FirebaseTools.uploadBeat(files[0]);
            sub.subscribe((data) => {
                setUploadingBeatPerc(data.percentage);
                setUploadTask(data.task);
                setStorageRef(data.ref);
                setUploadComplete(data.uploadComplete);
            });
            setFileHovering(false);
        }
    };

    const renderComponent = () => {
        switch (selectedComponent) {
            case 1:
            default:
                return <AllBeats onPlayBeat={(src: string) => playBeat(src)} refresh={getData}/>;
            case 2:
                return <Artists onPlayBeat={(src: string) => playBeat(src)} />;
            case 3:
                return <Genres onPlayBeat={(src: string) => playBeat(src)} />;
        }
    };

    const { height, width } = useWindowDimensions();

    return (
    <div className="App">
        <Drawer
            variant="permanent"
            open
        >
            <Toolbar />
            <div>
                <List style={{
                    width: width*0.17
                }}>
                    {menuElements.map((element, index) => (
                        <ListItem button key={element.name} onClick={() => setSelectedComponent(element.menuComponent)}>
                            <ListItemIcon>{element.iconComponent}</ListItemIcon>
                            <ListItemText primary={element.name} />
                        </ListItem>
                    ))}
                </List>
            </div>
        </Drawer>
        <FileDrop
            onFrameDragEnter={(event) => setFileHovering(true)}
            onFrameDragLeave={(event) => setFileHovering(false)}
            onDrop={(files, event) => uploadBeat(files)}
        >
            <Dialog
                open={uploadingBeat}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                    <DialogContent >
                        <Dialog onClose={() =>{}} open={true} >
                            <DialogTitle id="customized-dialog-title">
                                Upload Beat
                            </DialogTitle>
                            <DialogContent dividers>
                                <TextField id="standard-basic" label="Name" value={nameValue} onChange={(e) =>setNameValue(e.target.value)} fullWidth margin="dense"/>
                                <TextField id="standard-basic" label="Key" value={keyValue} onChange={(e) =>setKeyValue(e.target.value)} fullWidth margin="dense"/>
                                <TextField id="standard-basic" label="BPM" value={bpmValue} onChange={(e) =>setBpmValue(e.target.value)} fullWidth margin="dense"/>
                                <div style={{
                                    backgroundColor: 'rgba(255,245,159,0.73)',
                                    display: 'flex',
                                    borderColor: 'rgb(100,95,41)',
                                    borderWidth: 1,
                                    borderRadius: 6,
                                    marginTop: 12,
                                    padding: 8, flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    flex: 1,
                                }}>
                                    <WarningTwoToneIcon style={{color: 'rgb(100,95,41)', marginRight: 4}}/>
                                    <Typography style={{color: 'rgb(100,95,41)'}}>
                                        BPM is an estimate (bêta), please check the value before submiting.
                                    </Typography>
                                </div>
                                <Box display="flex" alignItems="center" style={{marginTop: 18}}>
                                    <Box width="100%" mr={1}>
                                        <LinearProgress style={{height: 10, borderRadius: 6}} value={uploadingBeatPerc} variant="determinate"/>
                                    </Box>
                                    <Box minWidth={10}>
                                        <Typography variant="body2" color="textSecondary">{`${Math.round(
                                            uploadingBeatPerc
                                        )}%`}</Typography>
                                    </Box>
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                {
                                    cancelState ?
                                        <>
                                                <Typography color={'secondary'}>
                                                    Cancel ?
                                                </Typography>
                                                <Button disabled={canceling} color={'secondary'} onClick={cancelUpload}>Yes</Button>
                                                <Button disabled={canceling} color={'secondary'} onClick={() => setCancelState(false)}>No</Button>
                                        </>
                                        :
                                        <Button disabled={canceling} color="secondary" onClick={() => setCancelState(true)}>
                                            Cancel
                                        </Button>
                                }
                                <Button
                                    onClick={publishBeat}
                                    color="primary"
                                    disabled={
                                        !uploadComplete ||
                                        nameValue.length === 0 ||
                                        keyValue.length === 0 ||
                                        bpmValue.length === 0 ||
                                        cancelState ||
                                        canceling
                                    }>
                                    Upload beat
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </DialogContent>
            </Dialog>
            <Backdrop style={{zIndex: 10000}} open={fileHovering && !loading}>
                <CloudUploadOutlined style={{color: 'white'}} className="svg_icons_bordered"/>`
            </Backdrop>
                    {
                        loading ?
                            <Backdrop style={{backgroundColor: 'black', zIndex: 10000}} open={true}>


                                {

                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        height: "100%"
                                    }}>
                                        {
                                           /*
                                            <AsciiLoader/>
                                            */
                                        }
                                        <div style={{color: '#36bd50'}}>
                                            <Loader
                                                type="Audio"
                                                color='#36bd50'
                                                height={100}
                                                width={100}
                                                timeout={3000} //3 secs

                                            />
                                        </div>
                                    </div>

                                }
                            </Backdrop>
                            :
                            <div style={{marginLeft: width*0.17}}>

                            <Paper>
                                <div>
                                   {renderComponent()}
                                </div>
                                <div style={{
                                    position: 'fixed',
                                    marginLeft: width*0.17,
                                    bottom: 0,
                                    right: 0,
                                    left: 0
                                }}>
                                    <AudioPlayer
                                        autoPlay
                                        src={currentlyPlaying}
                                        onPlay={e => console.log("onPlay")}
                                        autoPlayAfterSrcChange={true}
                                        // other props here
                                    />
                                </div>
                            </Paper>

                        </div>
                    }
        </FileDrop>
    </div>
  );
}

export default App;
