import React, {useCallback, useContext, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {AppContext} from "../AppContext";
import {Grid, IconButton, InputBase, List, ListItem, ListItemText, ListSubheader, Paper} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody";

import DoneAll from '@material-ui/icons/DoneAll';
import Done from '@material-ui/icons/Done';
import Clear from '@material-ui/icons/Clear';
import PlayCircleOutline from '@material-ui/icons/PlayCircleOutline';
import PauseCircleOutline from '@material-ui/icons/PauseCircleOutline';
// @ts-ignore
import UseAnimations from "react-useanimations";
import {FirebaseTools} from "../Tools/FirebaseTools";
import axios from "axios";
import {Beat} from "../AllBeats/AllBeats";

interface Column {
    id: 'name' | 'bpm' | 'key';
    label: string;
    width?: string;
    align?: 'right' | 'left';
    format?: (value: number) => string;
}

const columns: Column[] = [
    { id: 'name', label: 'Name', width: "50%" },
    { id: 'bpm', label: 'BPM', width: "30%" },
    {
        id: 'key',
        label: 'Key',
        width: "20%",
    }
];

const useStyles = makeStyles({
    root: {
    },
    container: {
        maxHeight: "85vh",
    },
});

export const Artists = (props: any) => {

    const [artists, setArtists, genres, setGenres, beats, setBeats] = useContext(AppContext);

    const [beatPlaying, setBeatPlaying] = useState<any>();

    const playBeat = (beat: Beat) => {
        setBeatPlaying(beat);
        FirebaseTools.getBeatMP3(beat.fullPath).then((url) => {
            props.onPlayBeat(url)
        })
    };

    const [selectedIndex, setSelectedIndex] = React.useState<number | null>(null);
    const [selectedArtist, setSelectedArtist] = React.useState<any | null>(null);

    const [searchString, setSearchString] = useState('');

    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
        artist: any
    ) => {
        setSelectedIndex(index);
        setSelectedArtist(artist)
    };

    const classes = useStyles();

    const downloadBeat = (fullPath: string) => {

        FirebaseTools.getBeatMP3(fullPath).then((url) => {

            axios({
                url,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                const fileURL = window.URL.createObjectURL(new Blob([response.data]));
                const fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', fullPath.replace('beats/', ''));
                document.body.appendChild(fileLink);

                fileLink.click();
            });

        })
    };

    const displayedBeats = useCallback(() => {
        if( selectedArtist && beats) {
            return selectedArtist
                && beats
                && beats.filter((beat: any) =>
                {
                    console.log("BEAT ", beat);
                    return beat.tags && beat.tags.artists && Object.keys(beat.tags.artists).includes(selectedArtist.id)
                })
        }
        return []
    }, [beats, selectedArtist])

    return (
        <Grid container>
            <Grid item xs={3} style={{height: "90vh", overflowY: 'scroll'}}>
                <List component="nav" >
                    <ListSubheader style={{ backgroundColor: 'rgb(66,66,66)', paddingBottom: 8}}>
                        <Paper style={{padding: 8, margin: 8}}>
                            <InputBase
                                placeholder="Search artist"
                                inputProps={{ 'aria-label': 'search google maps' }}
                                value={searchString}
                                onChange={(event) => setSearchString(event.target.value)}
                            />
                        </Paper>
                    </ListSubheader>
                    {
                        artists.filter((o: any) =>
                            Object.keys(o).some(() => o.name.toLowerCase().includes(searchString.toLowerCase()))).sort((a: any, b: any) =>  a.name && a.name.localeCompare(b.name)).map((artist: any, index: number) => {
                            return  <ListItem
                                button
                                selected={selectedIndex === index}
                                onClick={(event) => handleListItemClick(event, index, artist)}
                            >
                                <ListItemText primary={artist.name}/>
                            </ListItem>
                        })
                    }
                </List>
            </Grid>
            <Grid item xs={9} style={{ height: "90vh"}}>
                {
                    beats ?
                        <>
                            <TableContainer className={classes.container}>
                                <Table  stickyHeader={true} aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                key={"play"}
                                                align={"left"}
                                                style={{ width: "5%" }}
                                            />
                                            {columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ width: column.width }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                            <TableCell
                                                key={"tagged"}
                                                align={"left"}
                                                style={{ width: "5%" }}
                                            >
                                                Tagged
                                            </TableCell>
                                            <TableCell
                                                key={"download"}
                                                align={"left"}
                                                style={{ width: "5%" }}
                                            >
                                                Download
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            displayedBeats().map((row: any, index: any) => {
                                            return (
                                                <>
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.fullPath}>
                                                        <TableCell key={'play' + row.fullPath} align={'left'}>
                                                            <IconButton onClick={() => {playBeat(row)}}  style={{color: '#36bd50'}} aria-label="upload picture" component="span">
                                                                {beatPlaying === row ? <PauseCircleOutline fontSize={'large'}/> : <PlayCircleOutline fontSize={'large'}/>}
                                                            </IconButton>
                                                        </TableCell>
                                                        {columns.map((column, i) => {
                                                            const value = row[column.id];
                                                            return (
                                                                <TableCell key={column.id + i} align={'left'} onClick={() => {/*setRowExpanded(rowExpanded === index ? null : index)*/}}>
                                                                    {column.format && typeof value === 'number' ? column.format(value) : value}
                                                                </TableCell>
                                                            );
                                                        })}
                                                        <TableCell key={'tagged' + row.fullPath} align={'left'}>
                                                            {row.tags.artists.length > 0 ? row.tags.genres.length > 0 ? <DoneAll color={'primary'}/> : <Done color={'primary'}/> : <Clear color={'primary'}/>}
                                                        </TableCell>
                                                        <TableCell key={'download' + row.fullPath} align={'left'} >
                                                            <IconButton onClick={() => {downloadBeat(row.fullPath)}} color={'primary'} component="span">
                                                                <UseAnimations
                                                                    animationKey="download"
                                                                    size={28}
                                                                />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                        : null
                }
            </Grid>
        </Grid>
    )
};
