import firebase from "firebase";
import {Beat} from "../AllBeats/AllBeats";
import {Observable} from "rxjs";
import moment from "moment";

export enum TagType {
    ARTIST= "artists",
    GENRE= "genres"
}

export class FirebaseTools {

    static publishBeat = (name: string, key: string, bpm: number, fullPath: string): Promise<any> => {
        const addedOn = moment().format();
        return firebase.database().ref("beats").push({name, key, bpm, fullPath, addedOn}).then((data) => {
            if(data && data.key && data.key.length > 0) {
                const id = data.key;
                return {id, name, key, bpm, fullPath, tags: {artists: [], genres: []}}

            }
        })
    };

    static uploadBeat(file: File): Observable<{percentage: number, ref: firebase.storage.Reference, task: firebase.storage.UploadTask, uploadComplete: boolean}> {
        const storageRef = firebase.storage().ref();
        const beatsRef = storageRef.child('beats/' + file.name);

        const upload = beatsRef.put(file);

        return Observable.create((observer: any) => {

            upload.on(
                "state_changed",
                function progress(snapshot) {
                    const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    observer.next({percentage, task: upload, ref: beatsRef, uploadComplete: false})
                },

                function error() {
                    observer.next({percentage: 0, task: upload, ref: beatsRef, uploadComplete: false})
                },

                function complete() {
                    observer.next({percentage: 100, task: upload, ref: beatsRef, uploadComplete: true})
                }
            );
        });

    };

    static createData(id: string, fullPath: string, name: string, bpm: number, key: string, addedOn: string, tags: {artists: string[], genres: string[]}): Beat {
        console.log('createData');
        return {id, fullPath, name, bpm, key, addedOn, tags };
    }

    static getBeats(): Promise<Beat[]> {
        console.log('getBeatsPage');
        return firebase.database().ref('beats').orderByValue().once('value').then((snapshot) => {
            const beatsData = snapshot.val();

            return beatsData && Object.values(beatsData || {}).map((beat) => {
                const id = Object.keys(beatsData).find(key => beatsData[key] === beat);
                // @ts-ignore
                return this.createData(id, beat.fullPath, beat.name, beat.bpm, beat.key, beat.addedOn, {artists: (beat.tags && beat.tags.artists) || [], genres: (beat.tags && beat.tags.genres) || []})
            })
        })
    }

    static getArtists(): Promise<any[]> {
        return firebase.database().ref('artists').orderByValue().once('value').then((snapshot) => {
            console.log('getArtists');
            const artistsData = snapshot.val();

            return Object.values(artistsData || {}).map((artist: any) => {
                const id = Object.keys(artistsData).find(key => artistsData[key] === artist);
                 return {name: artist.name, id}
            })
        })
    }

    static getGenres(): Promise<any> {
        return firebase.database().ref('genres').orderByValue().once('value').then((snapshot) => {
            console.log('getGenres');
            const genresData = snapshot.val();

            return Object.values(genresData || {}).map((genre: any) => {
                const id = Object.keys(genresData).find(key => genresData[key] === genre);
                return {name: genre.name, id}
            })
        })
    }


    static getBeatMP3(fullPath: string): Promise<string> {
        console.log('getBeatMP3')
        return firebase.storage().ref().child(fullPath).getDownloadURL().then((url) => {
            return url;
        })
    }

    static addTagToBeat(beatId: string, tagId: string, tagType: TagType): Promise<void> {
        console.log('addTagToBeat')
        const beatRef = firebase.database().ref('beats/' + beatId);
        return firebase.database().ref('beats/' + beatId + '/tags/' + tagType).update({
            [tagId]: true
        }).then(() => {
            return beatRef.once('value').then((snapshot) => {
                const data = snapshot.val();
                const beat = data;
                beat.id = snapshot.key;

                return beat
            })
        })
    };

    static deleteTagFromBeat(beatId: string, tagId: string, tagType: TagType): Promise<void> {
        console.log('deleteTagFromBeat');
        return firebase.database().ref('beats/' + beatId + '/tags/' + tagType + '/' + tagId).remove().then(() => {
            return;
        })
    }

    static createTag(tagName: string, tagType: TagType): Promise<any> {
        console.log('createTag')
        return firebase.database().ref(tagType + "/").push({name: tagName}).then((tag) => {
            return tag.once('value').then((snapshot) => {
                const data = snapshot.val()
                const tag = {name: data.name, id: snapshot.key};
                return tag
            });
          //  return tag.ref.key as string
        })
    }
}