import React, {useContext, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import {FirebaseTools} from "../Tools/FirebaseTools";
// @ts-ignore
import UseAnimations from "react-useanimations";
import DoneAll from '@material-ui/icons/DoneAll';
import Done from '@material-ui/icons/Done';
import Clear from '@material-ui/icons/Clear';
import PlayCircleOutline from '@material-ui/icons/PlayCircleOutline';
import PauseCircleOutline from '@material-ui/icons/PauseCircleOutline';
import {
    AppBar,
    Backdrop,
    CircularProgress,
    Collapse,
    IconButton,
    InputBase,
    MenuItem,
    Select,
    Typography
} from "@material-ui/core";
import axios from 'axios';
import {BeatDetails} from "./BeatDetails";
import MusicOffTwoTone from '@material-ui/icons/MusicOffTwoTone';
import {AppContext} from "../AppContext";
import useWindowDimensions from "../Hooks/UseWindowDImension";
import moment from "moment";

export interface Beat {
    id: string;
    fullPath: string;
    name: string;
    bpm: number;
    key: string;
    addedOn?: string;
    tags: {
        artists: string[];
        genres: string[];
    }
}

export interface Artist {
    name: string;
}

interface Column {
    id: 'name' | 'bpm' | 'key' | 'addedOn';
    label: string;
    width?: string;
    align?: 'right' | 'left';
    format?: (value: number) => string;
    onClick?: () => void
};

const toFirstUpper = (s: string) => {
    return s.charAt(0).toUpperCase() + s.slice(1)
};

const useStyles = makeStyles({
    root: {
    },
    container: {
        maxHeight: "85vh",
    },
});

interface AllBeatsProps {
    onPlayBeat: (src: string) => void;
    refresh: () => Promise<void>;
}

enum SortType {
    BY_DATE = 'BY_DATE',
    BY_NAME = 'BY_NAME',
    BY_BPM = 'BY_BPM',
    BY_KEY = 'BY_KEY'
}

enum SearchFilter {
    ANY = 'any',
    NAME = 'name',
    BPM = 'bpm',
    KEY = 'key'
}

export const AllBeats = (props: AllBeatsProps) => {

    const columns: Column[] = [
        { id: 'name', label: 'Name', width: "40%", onClick: () => {setSortBy(SortType.BY_NAME)} },
        { id: 'bpm', label: 'BPM', width: "20%", onClick: () => {setSortBy(SortType.BY_BPM)} },
        {
            id: 'key',
            label: 'Key',
            width: "10%",
            onClick: () => {setSortBy(SortType.BY_KEY)}
        },
        {
            id: 'addedOn',
            label: 'Added On',
            width: "20%",
            onClick: () => {setSortBy(SortType.BY_DATE)}
        }
    ];


    const [artists, setArtists, genres, setGenres, beats, setBeats] = useContext(AppContext);

    const { height, width } = useWindowDimensions();

    // const [rows, setRows] = useState<Beat[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [rowExpanded, setRowExpanded] = useState<number|null>();
    const [beatPlaying, setBeatPlaying] = useState<any>();

    const [sortBy, setSortBy] = useState<SortType>(SortType.BY_DATE);
    const [searchString, setSearchString] = useState<string>('');
    const [searchFilter, setSearchFilter] = useState<SearchFilter>(SearchFilter.ANY);

    const playBeat = (beat: Beat) => {
        setBeatPlaying(beat);
        FirebaseTools.getBeatMP3(beat.fullPath).then((url) => {
            props.onPlayBeat(url)
        })
    };

    const compare = (a: Beat, b: Beat) => {
        switch (sortBy) {
            case SortType.BY_BPM:
                return a.bpm - b.bpm;
            case SortType.BY_DATE:
                return moment(b.addedOn).diff(moment(a.addedOn));
            case SortType.BY_NAME:
                return a.name.localeCompare(b.name);
            case SortType.BY_KEY:
                return a.key.localeCompare(b.key);
        }
    };

    const filter = (beat: Beat): boolean => {
        let match = false;

        Object.entries(beat).forEach((value: [string, any]) => {
            if(searchFilter === SearchFilter.ANY) {
                if (value[0] === SearchFilter.NAME || value[0] === SearchFilter.BPM || value[0] === SearchFilter.KEY) {
                    match = match || ('' + value[1]).toLowerCase().includes(searchString.toLowerCase());
                }
            } else {
                if(value[0] === searchFilter) {
                    match = match || ('' + value[1]).toLowerCase().includes(searchString.toLowerCase());
                }
            }
        });

        return match
    };

    const downloadBeat = (fullPath: string) => {

        FirebaseTools.getBeatMP3(fullPath).then((url) => {

            axios({
                url,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                const fileURL = window.URL.createObjectURL(new Blob([response.data]));
                const fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', fullPath.replace('beats/', ''));
                document.body.appendChild(fileLink);

                fileLink.click();
            });

        })
    };

    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [loadedPages, setLoadedPages] = useState<number[]>([]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const refreshAll = (): Promise<void> => {
            return props.refresh().then(() => {
                FirebaseTools.getBeats().then((beats) => {
                    setLoadedPages([...loadedPages, page]);
                    setLoading(false);
                    //setRows(beats);
                })
            })
    };

    return (
        loading ?
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: "100%"
            }}><CircularProgress color={"primary"} size={60} /></div>
            :
            beats ?
                <>
                    <AppBar position="static" style={{flexDirection: 'row'}}>
                        <InputBase
                            placeholder={"Search " + (searchFilter === 'any' ? 'anything' : searchFilter)}
                            inputProps={{ 'aria-label': 'search google maps' }}
                            value={searchString}
                            onChange={(event) => setSearchString(event.target.value)}
                            style={{
                                padding: 8,
                                margin: 8,
                                width: width*70,
                                backgroundColor:'#606060',
                                borderRadius: 6
                            }}
                        />
                        <Select
                            value={searchFilter}
                            onChange={(event) => setSearchFilter(event.target.value as SearchFilter)}
                            style={{
                                width: width*13,
                                margin: 8
                            }}
                        >
                            <MenuItem value={SearchFilter.ANY}>{toFirstUpper(SearchFilter.ANY)}</MenuItem>
                            <MenuItem value={SearchFilter.NAME}>{toFirstUpper(SearchFilter.NAME)}</MenuItem>
                            <MenuItem value={SearchFilter.BPM}>{toFirstUpper(SearchFilter.BPM)}</MenuItem>
                            <MenuItem value={SearchFilter.KEY}>{toFirstUpper(SearchFilter.KEY)}</MenuItem>
                        </Select>
                    </AppBar>
                    <TableContainer className={classes.container}>
                        <Table stickyHeader={true} aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        key={"play"}
                                        align={"left"}
                                        style={{ width: "5%" }}
                                    />
                                    {columns.map((column) => (
                                        <TableCell
                                            onClick={column.onClick}
                                            key={column.id}
                                            align={column.align}
                                            style={{
                                                width: column.width,
                                                cursor: 'pointer'
                                            }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                    <TableCell
                                        key={"tagged"}
                                        align={"left"}
                                        style={{ width: "5%" }}
                                    >
                                        Tagged
                                    </TableCell>
                                    <TableCell
                                        key={"download"}
                                        align={"left"}
                                        style={{ width: "5%" }}
                                    >
                                        Download
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {beats && beats.filter(filter).sort(compare).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: any, index: any) => {
                                    return (
                                        <>
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.fullPath}>
                                                <TableCell key={'play' + row.fullPath} align={'left'}>
                                                    <IconButton onClick={() => {playBeat(row)}}  style={{color: '#36bd50'}} aria-label="upload picture" component="span">
                                                        {beatPlaying === row ? <PauseCircleOutline fontSize={'large'}/> : <PlayCircleOutline fontSize={'large'}/>}
                                                    </IconButton>
                                                </TableCell>
                                                {columns.map((column, i) => {

                                                    const value = row[column.id];

                                                    return (
                                                        <TableCell key={column.id + i} align={'left'} onClick={() => {setRowExpanded(rowExpanded === index ? null : index)}}>
                                                            {column.id === 'addedOn' ? moment(value).format('LL') : column.format && typeof value === 'number' ? column.format(value) : value}
                                                        </TableCell>
                                                    );
                                                })}
                                                <TableCell key={'tagged' + row.fullPath} align={'left'}>
                                                    {
                                                        Object.keys(row.tags.artists || {}).length > 0 && Object.keys(row.tags.genres || {}).length > 0 ?
                                                            <DoneAll color={'primary'}/> :
                                                            Object.keys(row.tags.artists || {}).length > 0 || Object.keys(row.tags.genres || {}).length > 0 ?
                                                                <Done color={'primary'}/> :
                                                            <Clear color={'primary'}/>
                                                    }
                                                </TableCell>
                                                <TableCell key={'download' + row.fullPath} align={'left'} >
                                                        <IconButton onClick={() => downloadBeat(row.fullPath)} color={'primary'} component="span">
                                                            <UseAnimations
                                                                animationKey="download"
                                                                size={28}
                                                            />
                                                        </IconButton>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ paddingBottom: 0, paddingTop: 0}} colSpan={12}>
                                                    <Collapse in={rowExpanded === index} timeout="auto" unmountOnExit>
                                                        {artists && genres ? <BeatDetails refresh={refreshAll} beat={row} /> : null}
                                                    </Collapse>
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={beats && (beats.length || 0)}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </>
                :
                <Backdrop style={{marginLeft: width*0.17, height: "90vh", flex: 1, flexDirection: 'column'}} open={true}>
                    <MusicOffTwoTone style={{color: 'white'}} className="svg_icons"/>
                    <Typography variant="h5" style={{textAlign: 'center'}}>
                        Looks like there is no music here...
                    </Typography>
                    <Typography variant="h4" style={{textAlign: 'center'}}>
                        Drag & drop your first song to start !
                    </Typography>
                </Backdrop>
    );

    //return (StickyHeadTable(rows))
};
