import React, {useContext, useEffect, useState} from "react";
import {Chip, Dialog, DialogTitle, Divider, Grid} from "@material-ui/core";
import Add from '@material-ui/icons/Add';
import {AddTagModal} from "./AddTagModal";
import {Beat} from "./AllBeats";
import {FirebaseTools, TagType} from "../Tools/FirebaseTools";
import {AppContext} from "../AppContext";

interface ChipData {
    id: number;
    name: string;
}

interface BeatDetailsProps {
    beat: Beat
    refresh: () => Promise<void>;
}

export const BeatDetails = (props: BeatDetailsProps) => {
    const [artists, setArtists, genres, setGenres, beats, setBeats] = useContext(AppContext);

    const [artistLabels, setArtistLabels] = useState<ChipData[]>([]);

    const [genreLabels, setGenreLabels] = useState<ChipData[]>([]);

    useEffect(() => {

        const artistsLabels: any = Object.keys(props.beat.tags.artists || {}).map((tag: any) => {
            return Object.values(artists).find((el: any) => el.id === tag);
        });

        setArtistLabels(artistsLabels);

        const genresLabel: any = Object.keys(props.beat.tags.genres || {}).map((tag: any) => {
            return Object.values(genres).find((el: any) => el.id === tag);
        });

        setGenreLabels(genresLabel);

    }, [artists, props.beat, genres, beats]);


    const [dialogOpened, setDialogOpened] = useState<{open: boolean, type: TagType}>({open: false, type: TagType.ARTIST});

    const handleDelete = (chipToDelete: any, tagType: TagType) => () => {
        switch (tagType) {
            case TagType.ARTIST:
                FirebaseTools.deleteTagFromBeat(props.beat.id, chipToDelete.id, TagType.ARTIST).then(() => {
                    let newBeatsArray: any;
                    if(beats != null && typeof beats[Symbol.iterator] === 'function') {
                        newBeatsArray = [...beats]
                    } else {
                        newBeatsArray = []
                    }
                    newBeatsArray.map((beat: Beat) => {
                        if(beat.id === props.beat.id)
                        {
                            const artistsTag = beat.tags && beat.tags.artists && Object.keys(beat.tags.artists).filter((artist: any) => {
                                return artist !== chipToDelete.id
                            });
                            beat.tags.artists = artistsTag.reduce((m: any, v: any) => (m[v] = true, m), {})
                        }
                        return beat
                    });

                    setBeats(newBeatsArray);
                });
                break;
            case TagType.GENRE:
                FirebaseTools.deleteTagFromBeat(props.beat.id, chipToDelete.id, TagType.GENRE).then(() => {
                    props.refresh().then()
                });
                break;
        }
    };

    const refresh = (): Promise<void> => {
        return props.refresh().then(() => {
         setDialogOpened({open: false, type: dialogOpened.type})
        })
    };

    return (
        <div>
            <Dialog onClose={() => setDialogOpened({open: false, type: dialogOpened.type})} aria-labelledby="simple-dialog-title" open={dialogOpened.open}>
                <DialogTitle id="simple-dialog-title">Add {dialogOpened.type === TagType.ARTIST ? "Artist" : "Genre"} Tag</DialogTitle>
                <AddTagModal type={dialogOpened.type} beat={props.beat} onClose={() => setDialogOpened({open: false, type: dialogOpened.type})}/>
            </Dialog>
            <Grid container alignItems={'center'} >
                <div style={{
                    display: 'flex',
                    width: "45%",
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    listStyle: 'none',
                    padding: 8,
                    margin: 0,
                }}>
                    <h3>Artists</h3>
                </div>
                    <Divider orientation="vertical" flexItem />
                    <div style={{
                        display: 'flex',
                        width: "45%",
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        listStyle: 'none',
                        padding: 8,
                        margin: 0,
                    }}>
                    <h3>Genres</h3>
                </div>
            </Grid>
            <Grid container alignItems={'center'}>
                <ul style={{
                    display: 'flex',
                    width: "45%",
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    listStyle: 'none',
                    padding: 8,
                    margin: 0,
                }}>
                    {artistLabels.map((data) => {
                        let icon;

                        return (
                            <li key={data.id}>
                                <Chip
                                    clickable
                                    color="primary"
                                    icon={icon}
                                    label={data.name}
                                    onDelete={handleDelete(data, TagType.ARTIST)}
                                    style={{
                                        margin: 8,
                                    }}
                                />
                            </li>
                        );
                    })}
                    <li key={"addTag"}>
                        <Chip
                            clickable
                            variant="outlined"
                            deleteIcon={<Add/>}
                            label={"Add Tag"}
                            onClick={() => setDialogOpened({open: true, type: TagType.ARTIST})}
                            onDelete={() => setDialogOpened({open: true, type: TagType.ARTIST})}
                            style={{
                                margin: 8,
                            }}
                        />
                    </li>
                </ul>
                <Divider orientation="vertical" flexItem />
                <ul style={{
                    display: 'flex',
                    width: "45%",
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    listStyle: 'none',
                    padding: 8,
                    margin: 0,
                }}>
                    {genreLabels.map((data) => {
                        let icon;

                        return (
                            <li key={data.id}>
                                <Chip
                                    clickable
                                    color="primary"
                                    icon={icon}
                                    label={data.name}
                                    onDelete={handleDelete(data, TagType.GENRE)}
                                    style={{
                                        margin: 8,
                                    }}
                                />
                            </li>
                        );
                    })}
                    <li key={"addTag"}>
                        <Chip
                            clickable
                            variant="outlined"
                            deleteIcon={<Add/>}
                            label={"Add Tag"}
                            onClick={() => setDialogOpened({open: true, type: TagType.GENRE})}
                            onDelete={() => setDialogOpened({open: true, type: TagType.GENRE})}
                            style={{
                                margin: 8,
                            }}
                        />
                    </li>
                </ul>
            </Grid>
        </div>

    );

};
