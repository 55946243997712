import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {AppContextProvider} from "./AppContext";
import firebase from "firebase";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const firebaseConfig = {
    apiKey: "AIzaSyCq01IGV53GrwHeae5P3RXSWGwl5XKt7JE",
    authDomain: "snooze-beats.firebaseapp.com",
    databaseURL: "https://snooze-beats.firebaseio.com",
    projectId: "snooze-beats",
    storageBucket: "snooze-beats.appspot.com",
    messagingSenderId: "167909483470",
    appId: "1:167909483470:web:823858c0e95402adc6419f",
    measurementId: "G-KWE2CW4TK6"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const theme = createMuiTheme({
            palette: {
                type: 'dark',
                primary: {main: 'rgb(42,154,193)'}
            },
        });

ReactDOM.render(
  <React.StrictMode>
      <ThemeProvider theme={theme}>
          <AppContextProvider>
            <App />
          </AppContextProvider>
      </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
