/* eslint-disable no-use-before-define */
import React, {useContext, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import {Artist} from "./AllBeats";
import {Button, List} from "@material-ui/core";
import {FirebaseTools, TagType} from "../Tools/FirebaseTools";
import {AppContext} from "../AppContext";

const filter = createFilterOptions<Artist>();

interface AddTagModalProps {
    beat: any
    type: TagType
    onClose: () => void;
}

export const AddTagModal = (props: AddTagModalProps) => {
    const [value, setValue] = React.useState<Artist | null>(null);

    const [addTagMode, setAddTagMode] = useState(false);

    const [artists, setArtists, genres, setGenres, beats, setBeats] = useContext(AppContext);

    const options = Object.values(props.type === TagType.ARTIST ? artists : genres);

    const addTag = (newTag: any): Promise<void> => {
        return FirebaseTools.addTagToBeat(props.beat.id, newTag.id, props.type).then((data: any) => {
            let newBeatsArray: any;
            if(beats != null && typeof beats[Symbol.iterator] === 'function') {
                newBeatsArray = [...beats].map((beat) => {
                    if(data.id === beat.id) {
                        return data
                    } else {
                        return beat
                    }
                });
            } else {
                newBeatsArray = [data]
            }

            setBeats(newBeatsArray);
            return Promise.resolve()
        })
    };

    const handleValue = () => {
        if (addTagMode) {
            const tagName = value && value.name && value.name.replace("Add ", "").replace(/"/g, "");
            if(tagName) {
                FirebaseTools.createTag(tagName, props.type).then((tag: any) => {
                    switch (props.type) {
                        case TagType.GENRE:
                            setGenres([...genres, tag]);
                            break;
                        case TagType.ARTIST:
                            setArtists([...artists, tag]);
                            break;
                        default:
                            break;
                    }

                    addTag(tag).then(() => {
                        props.onClose()
                    })
                })
            }
        } else {
            options.map((a: any) => {
                if (a.name.trim() === (value && value.name)) {
                    addTag(a).then(() => {
                        props.onClose()
                    })
                }
            })
        }
    };

    return (
        <div style={{padding: 8}}>
            <List style={{flexDirection: "column"}}>
                <Autocomplete
                    value={value}
                    onChange={(event, newValue: any) => {

                        if(newValue && typeof newValue.id === 'undefined') {
                            setAddTagMode(true)
                        } else {
                            setAddTagMode(false)
                        }

                        if (typeof newValue === 'string') {
                            setValue({
                                name: newValue,
                            });
                        } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setValue({
                                name: newValue.inputValue,
                            });
                        } else {
                            setValue(newValue);
                        }
                    }}
                    filterOptions={(options: any, params: any) => {
                        const filtered = filter(options, params);

                        // Suggest the creation of a new value
                        if (params.inputValue !== '') {

                            filtered.push({
                                name: `Add "${params.inputValue}"`
                            });
                        }

                        return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="free-solo-with-text-demo"
                    options={options}
                    getOptionLabel={(option: any) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        // Regular option
                        return option.name;
                    }}
                    renderOption={(option: any) => option.name}
                    style={{ width: 300 }}
                    freeSolo
                    renderInput={(params) => (
                        <TextField {...params} label="Tag" variant="outlined" />
                    )}
                />
            </List>
            <Button color="primary" variant={'outlined'} style={{width: "100%"}} onClick={() => {handleValue()}}>Add tag</Button>
        </div>

    );
};

